<template>
  <div class="tile is-parent is-half">
    <article class="tile is-child box detail-page-tile">
      <div class="columns is-gapless">
        <div class="column">
          <p class="title">Attachments</p>
        </div>
        <div class="column">
          <!-- cant apply disabled to file picker button -->
          <form ref="uploader"
            v-if="!readOnly"
            enctype="multipart/form-data"
            novalidate>
            <div class="field">
              <div class="file is-pulled-right is-primary">
                <label class="file-label">
                  <!-- <input class="file-input" type="file" name="resume"> -->
                  <input type="file"
                    class="file-input"
                    :name="uploadFieldName"
                    :disabled="isSaving || readOnly"
                    @change="filesChange($event.target.name, $event.target.files);fileCount = $event.target.files.length"
                    accept="image/png, image/jpeg, .pdf">
                  <span class="file-cta">
                    <span class="file-icon">
                      <i class="mdi mdi-upload mdi-20px" />
                    </span>
                    <span class="file-label">
                      Select files...
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
      <table v-if="!readOnly"
        class="table is-bordered is-striped is-narrow is-fullwidth">
        <thead>
          <tr>
            <th>
              Date
            </th>
            <th>
              File name
            </th>
            <th>
              File size
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item) in attachments"
            :key="item.id">
            <td class="has-vertical-middle">
              {{ $filters.formatDateTimeLocale(item.createdDate, $userInfo.locale) }}
            </td>
            <td class="has-vertical-middle">
              <a href="#"
                @click="downloadAttachment(item)">{{ item.fileName }}</a>
            </td>
            <td class="has-vertical-middle">
              {{ item.fileSize }}
            </td>
            <td class="has-vertical-middle has-text-centered">
              <a class="button is-danger is-inverted"
                @click="deleteAttachment(item)">
                <span class="icon is-medium">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <table v-else
        class="table is-bordered is-striped is-narrow is-fullwidth">
        <thead>
          <tr>
            <th>
              Date
            </th>
            <th>
              File name
            </th>
            <th>
              File size
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item) in attachments"
            :key="item.id">
            <td class="has-vertical-middle">
              {{ $filters.formatDateTimeLocale(item.createdDate, $userInfo.locale) }}
            </td>
            <td class="has-vertical-middle">
              <a href="#"
                @click="downloadAttachment(item)">{{ item.fileName }}</a>
            </td>
            <td class="has-vertical-middle">
              {{ item.fileSize }}
            </td>
          </tr>
        </tbody>
      </table>
    </article>
    <confirm-modal v-if="currentAttachment"
      :active.sync="isConfirmModalActive"
      @ok="confirmDelete(true)"
      @cancel="confirmDelete(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Attachment</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ currentAttachment.fileName }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import StoreMixin from './storeMixin'
import SundryCreditorService from './SundryCreditorService'
import UploadService from '@/components/FileUploadService'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { ConfirmModal } from '@/components/BulmaModal'
import QuoteService from '@/views/quote/QuoteService.js'

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export default {
  name: 'SundryCreditorAttachment',
  components: {
    ConfirmModal
  },
  mixins: [DateTimeFiltersMixin, StoreMixin],
  props: {
    entity: null
  },
  data() {
    return {
      quoteAttachmentCategories: [],
      attachments: [],
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      isConfirmModalActive: false,
      currentAttachment: null
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED
    },
    readOnly() {
      // return !this.entity.gstPaidDate === false
      return (!this.entity.gstPaidDate && !this.readOnlyView) === false
    }
  },
  async created() {
    this.quoteAttachmentCategories = await QuoteService.getQuoteAttachmentCategories()
  },
  mounted() {
    this.getAttachments()
    this.reset()
  },
  methods: {
    async getAttachments() {
      const req = await SundryCreditorService.getAttachments(this.entity.id)
      this.attachments = req.data.list
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadedFiles = []
      this.uploadError = null
    },
    getQuoteAttachmentCategoryId(name) {
      const categories = this.quoteAttachmentCategories.filter((row) => row.name == name)
      if (categories.length == 1) {
        return categories[0].quoteAttachmentCategoryId
      }

      return 'xxx'
    },
    save(formData) {
      this.$showSpinner()
      // upload data to the server
      this.currentStatus = STATUS_SAVING

      let quoteNo = this.entity.quoteNo
      const quoteAttachmentCategoryId = this.getQuoteAttachmentCategoryId('Supplier Credit Notes')
      UploadService.upload(formData, this.entity.id, quoteNo, quoteAttachmentCategoryId)
        .then((x) => {
          this.uploadedFiles = [].concat(x)
          this.currentStatus = STATUS_SUCCESS

          this.getAttachments()
          this.$refs.uploader.reset()
          this.$hideSpinner()
        })
        .catch((err) => {
          this.uploadError = err.response
          this.currentStatus = STATUS_FAILED
          this.$hideSpinner()
        })
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData()

      if (!fileList.length) return

      const vm = this
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        // console.log(fileList[x].name.match(/\.[0-9a-z]+$/i))
        // console.log(fileList[x].name.match(/\.([^.]+)$/))
        // console.log(fileList[x].name.match(/\.([0-9a-z]+)(?:[?#]|$)/))
        const regex = fileList[x].name.match(/\.([0-9a-z]+)(?:[?#]|$)/)
        const ext = regex[1]
        switch (ext) {
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'pdf':
            formData.append(fieldName, fileList[x], fileList[x].name)
            // save it
            this.save(formData)
            break
          default:
            vm.$notification.openNotificationWithType('danger', 'Attachments', 'File type not allowed. Only JPG, JPEG, PNG & PDF are allowed')
        }
      })
    },
    async deleteAttachment(attachment) {
      if (this.readOnly) {
        return false
      }

      this.currentAttachment = attachment
      this.isConfirmModalActive = true
    },
    async confirmDelete(decision) {
      this.isConfirmModalActive = false

      if (decision) {
        await UploadService.delete(this.currentAttachment.id)

        this.getAttachments()

        this.$refs.uploader.reset()
      }

      this.currentAttachment = null
    },
    async downloadAttachment(item) {
      const response = await UploadService.get(item.id)

      var blob = new Blob([response.data], {
        type: response.headers['content-type']
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = item.fileName
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      delete link.href

      return false
    }
  }
}
</script>
